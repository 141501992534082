<template>
  <div>

    
    <div align="center" v-if="Loading">
      <br /><br />
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-else>
      <h3>ข้อมูลผู้ใช้งาน LINE OA</h3>
      <div>
        <br />
        <b-row>
          <b-col xl="6" md="12">
            <b-card>
              <h4><b>ข้อมูลในระบบ</b></h4>
              <br />
              <div class="blue box ex4">
                <div class="coral item" align="center">
                  <!-- <feather-icon
                  icon="UsersIcon"
                  size="50"
                  class="text-muted cursor-pointer"
                />
                <br /><br /> -->
                  <h1>เพื่อนจากระบบ Wi-Fi</h1>
                  <h1>
                    <b>{{ wifi_users.user_all }}</b>
                  </h1>
                </div>

              
              </div>
            </b-card>
          </b-col>
          <b-col xl="6" md="12">
            <b-card>
              <h4><b>ข้อมูลจากไลน์</b></h4>
              <br />
              <div class="blue box ex4">
                <div class="coral item" align="center">
                  <!-- <feather-icon
                    icon="UsersIcon"
                    size="50"
                    class="text-muted cursor-pointer"
                  />
                  <br /><br /> -->
                  <h4>เพื่อนทั้งหมด</h4>
                  <h4>
                    <b>{{ line_friend.followers }}</b>
                  </h4>
                </div>

                <div class="coral item" align="center">
                  <!-- <feather-icon
                    icon="ShieldIcon"
                    size="50"
                    class="text-muted cursor-pointer"
                  />
                  <br /><br /> -->
                  <h4>ACTIVE</h4>
                  <h4>
                    <b>{{ line_friend.targetedReaches }} friends</b>
                  </h4>
                  <!-- <h4>
                  {{
                    Math.ceil(
                      (line_friend.targetedReaches * 100) /
                        line_friend.followers
                    )
                  }}
                  %
                </h4> -->
                </div>

                <div class="coral item" align="center">
                  <!-- <feather-icon
                    icon="EyeOffIcon"
                    size="50"
                    class="text-muted cursor-pointer"
                  />
                  <br /><br /> -->
                  <h4>BLOCKED</h4>
                  <h4>
                    <b>{{ line_friend.blocks }} friends</b>
                  </h4>
                  <!-- <h3>
                  {{
                    Math.ceil(
                      (line_friend.blocks * 100) / line_friend.followers
                    )
                  }}
                  %
                </h3> -->
                <br/>
                </div>
              </div>
            </b-card>
          </b-col>
      
        </b-row>

        <b-row>
          <b-col xl="12" md="12">
            <b-card no-body
              ><br />
              <div align="center"><h3>เพศ</h3></div>

              <vue-apex-charts
                type="donut"
                height="200"
                class="mt-2 mb-1"
                :options="customersPie.chartOptions"
                :series="customersPie.series"
              />
              <vue-apex-charts
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
              />
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col cols="7" xl="7" md="12">
            <b-card>
              <div align="center"><h3>กราฟแท่งแสดงช่วงอายุ</h3></div>
              <hr />
              <age :wifi_users="wifi_users"></age>
            </b-card>
          </b-col> -->
          <!-- <b-col xl="6" md="12">
            <b-card style="height: 320px">
              <div align="center">
                <h3>การเชื่อมไวไฟ</h3>
              </div>
              <section class="chat-app-window">
                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                >
                  <div class="chats">
                    <div>
                      <div
                        class="chat-body"
                        v-for="(item, index) in branch"
                        :key="index"
                      >
                        <b-media no-body>
                          <b-media-aside>
                            <b-avatar rounded size="30" :src="house_wifi" />
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h5 class="mb-0">
                              <b>
                                {{ item.name }}
                                {{ item.total_connectWIFI }} ครั้ง</b
                              >
                            </h5>
                           
                          </b-media-body>
                        </b-media>
                      </div>
                    </div>
                  </div>
                </vue-perfect-scrollbar>
              </section>
              <br />
              <br /><br /><br />
            </b-card>
          </b-col> -->

          <b-col xl="6" md="12">
            <b-card no-body>
              <br />
              <div align="center"><h3>อุปกรณ์</h3></div>
              <b-card-body>
                <vue-apex-charts
                  type="pie"
                  height="280"
                  class="mt-2 mb-1"
                  :options="osPie.chartOptions"
                  :series="osPie.series"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="6" md="12">
            <b-card>
              <div align="center"><h3>ระยะเวลาเพื่อน</h3></div>
              <hr />
              <b-row>
                <b-col
                  >7 วัน ({{
                    Math.ceil(
                      (wifi_users.firend7day * 100) / wifi_users.user_all
                    )
                  }}%)</b-col
                >
              </b-row>
              <b-progress
                :value="(wifi_users.firend7day * 100) / wifi_users.user_all"
                max="100"
                variant="success"
                height="8px"
                class="mt-25"
                style="margin-bottom: 10px"
              />
              <b-row>
                <b-col
                  >30 วัน ({{
                    Math.ceil(
                      (wifi_users.firend30day * 100) / wifi_users.user_all
                    )
                  }}%)</b-col
                >
              </b-row>
              <b-progress
                :value="(wifi_users.firend30day * 100) / wifi_users.user_all"
                max="100"
                variant="success"
                height="8px"
                class="mt-25"
                style="margin-bottom: 10px"
              />
              <b-row>
                <b-col
                  >1 เดือน ({{
                    Math.ceil(
                      (wifi_users.firend1Month * 100) / wifi_users.user_all
                    )
                  }}%)</b-col
                >
              </b-row>
              <b-progress
                :value="(wifi_users.firend1Month * 100) / wifi_users.user_all"
                max="100"
                variant="success"
                height="8px"
                class="mt-25"
                style="margin-bottom: 10px"
              />
              <b-row>
                <b-col
                  >3 เดือน ({{
                    Math.ceil(
                      (wifi_users.firend3Month * 100) / wifi_users.user_all
                    )
                  }}%)</b-col
                >
              </b-row>
              <b-progress
                :value="(wifi_users.firend3Month * 100) / wifi_users.user_all"
                max="100"
                variant="success"
                height="8px"
                class="mt-25"
                style="margin-bottom: 10px"
              />
              <b-row>
                <b-col
                  >6 เดือน ({{
                    Math.ceil(
                      (wifi_users.firend6Month * 100) / wifi_users.user_all
                    )
                  }}%)</b-col
                >
              </b-row>
              <b-progress
                :value="(wifi_users.firend6Month * 100) / wifi_users.user_all"
                max="100"
                variant="success"
                height="8px"
                class="mt-25"
                style="margin-bottom: 10px"
              />
              <b-row>
                <b-col
                  >12 เดือน ({{
                    Math.ceil(
                      (wifi_users.firend12Month * 100) / wifi_users.user_all
                    )
                  }}%)</b-col
                >
              </b-row>
              <b-progress
                :value="(wifi_users.firend12Month * 100) / wifi_users.user_all"
                max="100"
                variant="success"
                height="8px"
                class="mt-25"
                style="margin-bottom: 10px"
              />
              <b-row>
                <b-col
                  >มากกว่า 1 ปี ({{
                    Math.ceil(
                      (wifi_users.firend1Years * 100) / wifi_users.user_all
                    )
                  }}%)</b-col
                >
              </b-row>
              <b-progress
                :value="(wifi_users.firend1Years * 100) / wifi_users.user_all"
                max="100"
                variant="success"
                height="8px"
                class="mt-25"
                style="margin-bottom: 10px"
              />
              <hr />
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col cols="6" xl="6" md="12">
            <b-card >
              <div align="center"><h3>แท็ก</h3></div>
              <section class="chat-app-window">
                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                >
                  <div class="chats">
                   
                    <div>
                      <div
                        class="chat-body"
                        v-for="(item, index) in tag_insert"
                        :key="index"
                      >
                        <div>
                          <p>
                            <b-badge variant="primary">
                              {{ item[0] }}
                            </b-badge>
                            <span
                              >&nbsp;&nbsp;&nbsp;&nbsp;จำนวน
                              {{ item[1] }} คน</span
                            >
                          </p>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </vue-perfect-scrollbar>
              </section>
            </b-card>
          </b-col> -->
          <b-col cols="12" xl="12" md="12">
            <b-card >
              <div align="center"><h2>แท็ก</h2></div>
              <section class="chat-app-window">
                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                >
                  <div class="chats">
                    
                    <div>
                      <div
                        class="chat-body"
                        v-for="(item, index) in tag_auto"
                        :key="index"
                      >
                        <div>
                          <p>
                            <b-badge variant="primary">
                              {{ item[0] }}
                            </b-badge>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;จำนวน {{ item[1] }} คน</span >
                          </p>
                          <hr />
                        </div>
                      </div>

                      <div>
                        <div
                          class="chat-body"
                          v-for="(item, index) in tag_insert"
                          :key="index"
                        >
                          <div>
                            <p>
                              <b-badge variant="primary">
                                {{ item[0] }}
                              </b-badge>
                              <span
                                >&nbsp;&nbsp;&nbsp;&nbsp;จำนวน
                                {{ item[1] }} คน</span
                              >
                            </p>
                            <hr />
                          </div>
                        </div>

</div>

                    </div>
                  </div>
                </vue-perfect-scrollbar>
              </section>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BProgress,
  BBadge,
  BImg,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import VueApexCharts from "vue-apexcharts";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Age from "./AgeChart.js";
import house_wifi from "@/assets/images/non/house_wifi.svg";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    VuePerfectScrollbar,
    BProgress,
    BBadge,
    BImg,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Age,
  },
  setup() {
    return {
      house_wifi,
    };
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      customersPie: {
        series: [],
        chartOptions: {
          labels: ["ผู้หญิง", "ผู้ชาย", "อื่นๆ"],
          dataLabels: {
            enabled: false,
          },
          colors: ["#d43f54", "#5d245d"],
        },
      },
      osPie: {
        series: [],
        chartOptions: {
          labels: [],
          dataLabels: {
            enabled: false,
          },
          colors: ["#d43f54", "#5d245d"],
        },
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 20,
        wheelPropagation: false,
      },
      name_user: JSON.parse(localStorage.getItem("userData")),
      line_friend: null,
      wifi_users: null,
      os: null,
      branch: null,
      tag_insert: [],
      tag_auto: [],
      Loading: false,
      data_age: [],
      color: [],

      series: [
        {
          name: "ชาย",
          data: [],
        },
        {
          name: "หญิง",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "18-24",
            "25-34",
            "35-44",
            "45-54",
            "55-64",
            "65-ขึ้นไป",
          ],
        },
        colors: ["#d43f54", "#5d245d"],
        yaxis: {
          title: {
            // text: '$ (thousands)'
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " คน";
            },
          },
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {
      this.Loading = true;
      const params = {
        store_id: localStorage.getItem("store_id"),
        id: this.name_user.user_main,
      };
      api
        .post("report_line", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response line :>> ", response.data);
          this.line_friend = response.data.line_friend;
          this.color = response.data.color;
          this.wifi_users = response.data.wifi_users[0];
          console.log(this.wifi_users);
          var customersPie_series = [];
          customersPie_series.push(this.wifi_users.gender_female);
          customersPie_series.push(this.wifi_users.gender_male);

          this.series[0].data.push(this.wifi_users.male_age18_24);
          this.series[0].data.push(this.wifi_users.male_age25_34);
          this.series[0].data.push(this.wifi_users.male_age35_44);
          this.series[0].data.push(this.wifi_users.male_age45_54);
          this.series[0].data.push(this.wifi_users.male_age55_64);
          this.series[0].data.push(this.wifi_users.male_age56up);

          this.series[1].data.push(this.wifi_users.female_age18_24);
          this.series[1].data.push(this.wifi_users.female_age25_34);
          this.series[1].data.push(this.wifi_users.female_age35_44);
          this.series[1].data.push(this.wifi_users.female_age45_54);
          this.series[1].data.push(this.wifi_users.female_age55_64);
          this.series[1].data.push(this.wifi_users.female_age56up);

          this.customersPie = {
            ...this.customersPie,
            ...{
              series: customersPie_series,
              chartOptions: {
                labels: ["ผู้หญิง", "ผู้ชาย"],

                dataLabels: {
                  enabled: true,
                },
                colors: ["#5d245d", "#d43f54"],
                colorsText:['#000'],
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                          fontFamily: "Montserrat",
                        },
                        value: {
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                          formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}`;
                          },
                        },
                        total: {
                          show: false,
                          fontSize: "1.5rem",
                          label: "Operational",
                          formatter() {
                            return "31%";
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
          this.os = response.data.os;
          var osPie_series = [];
          var osPie_labels = [];
          for (let i = 0; i < this.os.length; i++) {
            osPie_series.push(this.os[i].count_os);
            osPie_labels.push(this.os[i].os);
          }

          this.osPie = {
            ...this.osPie,
            ...{
              series: osPie_series,
              chartOptions: {
                labels: osPie_labels,
                dataLabels: {
                  enabled: true,
                },
                colors: this.color,
                colorsText: this.color,
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                          fontFamily: "Montserrat",
                        },
                        value: {
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                          formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}`;
                          },
                        },
                        total: {
                          show: false,
                          fontSize: "1.5rem",
                          label: "Operational",
                          formatter() {
                            return "31%";
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };

          this.branch = response.data.branch;

          for (var vehicle in response.data.tag_insert) {
            this.tag_insert.push([vehicle, response.data.tag_insert[vehicle]]);
          }
          this.tag_insert.sort(function (a, b) {
            return a[1] - b[1];
          });

          for (var vehicle in response.data.tag_auto) {
            this.tag_auto.push([vehicle, response.data.tag_auto[vehicle]]);
          }
          this.tag_auto.sort(function (a, b) {
            return a[1] - b[1];
          });
          this.Loading = false;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fe718d;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
