// Sales Widget
import { Bar } from 'vue-chartjs'
import axios from 'axios';

export default ({
   extends: Bar,
   props: {

      wifi_users: {
         required: true,
      },

   },
   data: function () {
      return {
         color: {
            'primary': '#5D92F4',
            'warning': '#FFB70F',
            'danger': '#FF3739',
            'success': '#00D014',
            'info': '#00D0BD',
            'white': '#fff',
            'lightGrey': '#E8ECEE',
            'series1': '#ffe700',
            'series2': '#00d4bd',
            'series3': '#826bf8',
         },
         lineChartAxesColor: '#E9ECEF',
         legendFontColor: '#AAAEB3', // only works on react chart js 2
         chartGridColor: '#EAEAEA',
         axesColor: '#657786',
         shadowColor: 'rgba(0,0,0,0.3)',
         chartdata: null,
         options: {
            elements: {
               rectangle: {
                  borderWidth: 2,
                  borderSkipped: 'bottom',
               },
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
               display: false,
            },
            scales: {
               yAxes: [{
                  stacked: true,
                  ticks: {
                     display: true
                  },
                  gridLines: {
                     display: false,
                     drawBorder: false
                  }
               }],
               xAxes: [{
                  stacked: true,
                  ticks: {
                     padding: 10
                  },
                  barPercentage: 0.4,
                  categoryPercentage: 1.3,
                  gridLines: {
                     display: true,
                     drawBorder: false
                  }
               }]
            },
         }
      }
   },
   watch: {},
   mounted() {
      console.log('this.wifi_users :>> ', this.wifi_users);
      this.renderChart({
         // labels: ['0-9', '10-14', '15-19', '20-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50 ขึ้นไป'],
         labels:["18-24", "25-34", "35-44", "45-54", "55-64", "65-ขึ้นไป"],
         datasets: [
            {
               type: 'bar',
               label: 'Male',
               backgroundColor: '#f9f46c',
               hoverBackgroundColor: '#f9f46c',
               borderWidth: 0,
               data: [
                  this.wifi_users.male_age0_9,
                  this.wifi_users.male_age10_14,
                  this.wifi_users.male_age15_19,
                  this.wifi_users.male_age20_24,
                  this.wifi_users.male_age25_29,
                  this.wifi_users.male_age30_34,
                  this.wifi_users.male_age35_39,
                  this.wifi_users.male_age40_44,
                  this.wifi_users.male_age45_49,
                  this.wifi_users.male_age_UP_50,
               ]
            },
            {
               type: 'bar',
               label: 'Female',
               backgroundColor: '#9a94f5',
               hoverBackgroundColor: '#9a94f5',
               borderWidth: 0,
               data: [
                  this.wifi_users.female_age0_9,
                  this.wifi_users.female_age10_14,
                  this.wifi_users.female_age15_19,
                  this.wifi_users.female_age20_24,
                  this.wifi_users.female_age25_29,
                  this.wifi_users.female_age30_34,
                  this.wifi_users.female_age35_39,
                  this.wifi_users.female_age40_44,
                  this.wifi_users.female_age45_49,
                  this.wifi_users.female_age_UP_50,
               ]
            }
         ]
      }, this.options)



   }
})
